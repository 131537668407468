/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, Modal, notification } from "antd";
import { AuthApi } from "api/auth";
import { RequestOTPType } from "api/auth/type";
import { PlayerBankMapping } from "api/bank/type";
import CloseIcon from "assets/temporary/icons/Close.png";
import { ReactComponent as RightIcon } from "assets/temporary/icons/right_icon.svg";
import InputCustom from "components/Common/InputCustom";
import { REGEX_NUMBER } from "contants/regex";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useCountDownTime } from "hooks/useCountDownTime";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { selectUsername } from "store/auth";
import { selectBanks, selectEwallets } from "store/bank";
import { addPlayerBank } from "store/bank/bank.thunk";
import { selectProfile } from "store/user";
import { getWithdrawalForm } from "store/withdraw/withdraw.thunk";
import { StatusNotification } from "types";
import { BankType } from "types/bank";
import * as yup from "yup";

import { BankMappingStyle, BankStyle } from "./index.style";
import { ModalSelectBank } from "./ModalSelectBank";
type Props = {
  visible: boolean;
  onClose: () => void;
  bankType?: string;
};

const initBank: BankType = {
  bank_name: "",
  bank_id: "",
  mobile_image_on: "",
  web_image_on: "",
};
const ModalBankMapping = (props: Props) => {
  const { visible, onClose, bankType } = props;

  const { t } = useTranslation(["button", "withdraw", "validate"]);

  const profile = useAppSelector(selectProfile);

  const schema = yup.object({
    account_no:
      bankType === "0"
        ? yup.string().required(t("validate:FieldRequired"))
        : bankType === "1"
        ? yup
            .string()
            .test("account_no", t("validate:StartWith") + " 0", (value) => {
              if (value) {
                return value.startsWith("0");
              }
              return false;
            })
            .length(11, t("validate:CharactersLength") + " 11")
            .required(t("validate:FieldRequired"))
        : yup.string().required(t("validate:FieldRequired")),
    account_name: yup
      .string()
      .required(t("validate:FieldRequired"))
      .test(
        "accountName",
        "Bank account name must match the full name",
        (account_name: string) => {
          if (account_name.toLowerCase() === profile?.full_name.toLowerCase())
            return true;
          return false;
        }
      ),
    otp: yup
      .string()
      .trim()
      .required(t("validate:OTPValid"))
      .min(6, t("validate:OTPValid"))
      .max(6, t("validate:OTPValid")),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors, isValid },
  } = useForm<PlayerBankMapping>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      account_name: profile?.full_name,
    },
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
    });
  };
  const dispatch = useAppDispatch();
  const username = useAppSelector(selectUsername);

  const [openSelectBank, setOpenSelectBank] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bank, setBank] = useState<BankType>(initBank);
  const banks = useAppSelector(selectBanks);
  const ewallets = useAppSelector(selectEwallets);

  const [isTriggerTimer, setIsTriggerTimer] = useState<boolean>(false);
  const [_countdown, setTime, time, isCounting] = useCountDownTime(
    300,
    isTriggerTimer
  );

  useEffect(() => {
    if (visible) return;
    reset();
    setBank(initBank);
  }, [visible]);

  useEffect(() => {
    if (banks.length > 0 && bankType === "0") {
      setBank({
        bank_name: banks[0]?.bank_name,
        bank_id: banks[0]?.bank_id,
        mobile_image_on: banks[0]?.mobile_image_on,
        web_image_on: banks[0]?.web_image_on,
      });
    }

    if (ewallets.length > 0 && bankType === "1") {
      setBank({
        bank_name: ewallets[0]?.bank_name,
        bank_id: ewallets[0]?.bank_id,
        mobile_image_on: ewallets[0]?.mobile_image_on,
        web_image_on: ewallets[0]?.web_image_on,
      });
    }
  }, [visible]);

  useEffect(() => {
    if (!profile?.full_name) return;
    setValue("account_name", profile?.full_name);
  }, [profile]);

  const onSubmit: SubmitHandler<PlayerBankMapping> = async (data) => {
    setIsLoading(true);
    const res = await dispatch(
      addPlayerBank({
        ...data,
        username,
        id: bank.bank_id,
        mobile: String(profile?.mobile),
      })
    );
    setIsLoading(false);

    const { msg, status } = res.payload as any;
    if (status !== ResponseCode.SUCCESS) {
      openNotification("error", msg);
      return;
    }

    dispatch(getWithdrawalForm(username));
    openNotification("success", msg);
    onClose();
  };

  const getOtpCode = async () => {
    if (profile) {
      const dataRequest: RequestOTPType = {
        username: profile?.username,
        mobile: profile?.mobile,
        type: 6,
      };
      const res = await AuthApi.requestOTP(dataRequest);
      const resData = res.data;
      openNotification(
        resData.status === ResponseCode.SUCCESS ? "success" : "error",
        resData.msg
      );
      if (resData.status === ResponseCode.SUCCESS) {
        setIsTriggerTimer(true);
      }
    } else {
      throw new Error(
        "Unknown error. Data is missing username or phone number."
      );
    }
  };

  const checkDisabledButtonGetOtp = () => {
    const account_number = watch("account_no");
    if (isCounting || isLoading) return true;

    if (!account_number) return true;
    return false;
  };

  const checkDisabledButtonAddBank = () => {
    if (!isValid) return true;
    return false;
  };

  useEffect(() => {
    if ((time as number) > 0) return;

    (setTime as Dispatch<SetStateAction<number>>)(300);
    setIsTriggerTimer(false);
  }, [time]);

  return (
    <Modal
      centered
      open={visible}
      closable={false}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
      css={BankStyle.modalContainer}
    >
      <div css={BankStyle.container}>
        <div css={BankStyle.header}>
          <div css={css({ width: 24, height: 24 })} />
          <span>{t("withdraw:BankMap")}</span>
          <Button onClick={onClose} css={BankStyle.buttonClose}>
            <img src={CloseIcon} alt="" />
          </Button>
        </div>
        <div css={[BankStyle.content, BankMappingStyle.content]}>
          <Form
            className="deposit_form"
            onSubmitCapture={handleSubmit(onSubmit)}
          >
            <div css={BankStyle.bank} onClick={() => setOpenSelectBank(true)}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p>{bank.bank_name || t("withdraw:SelectBank")}</p>
                <div>
                  <RightIcon />
                </div>
              </div>
            </div>

            <div>
              <InputCustom
                control={control}
                name="account_name"
                placeholder={
                  bankType === "0"
                    ? t("withdraw:BankAccountName")
                    : bankType === "1"
                    ? t("withdraw:EwalletAccountName")
                    : ""
                }
                value={profile?.full_name}
                disabled
              />
            </div>
            {bankType === "0" && (
              <div>
                <div className="notes">*{t("personal:NotesAddBank")}</div>
                <InputCustom
                  control={control}
                  name="account_no"
                  type="number"
                  errors={errors.account_no?.message}
                  disabled={isLoading}
                  validate_status={errors.account_no && "error"}
                  margin_bottom="20px"
                  placeholder={t("withdraw:BankAccountNumber")}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    (e.target.value =
                      e.target.value.match(REGEX_NUMBER)?.[0] || "")
                  }
                />
              </div>
            )}

            {bankType === "1" && (
              <div>
                <div className="notes">*{t("personal:NotesAddBank")}</div>
                <InputCustom
                  control={control}
                  name="account_no"
                  type="number"
                  errors={errors.account_no?.message}
                  disabled={isLoading}
                  validate_status={errors.account_no && "error"}
                  margin_bottom="20px"
                  placeholder={t("withdraw:EwalletAccountNumber")}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    (e.target.value =
                      e.target.value.match(REGEX_NUMBER)?.[0] || "")
                  }
                />
              </div>
            )}

            <Form.Item
              style={{ marginBottom: "0px" }}
              help={errors.otp?.message}
              validateStatus={errors.otp ? "error" : "success"}
            >
              <Controller
                name={"otp"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <div css={BankStyle.optCode}>
                      <input
                        placeholder={t("placeholder:OTPcode")}
                        value={value || ""}
                        onChange={onChange}
                        maxLength={6}
                        disabled={isLoading}
                        onKeyDown={(e) => {
                          const key = e.key;

                          if (key === "Backspace") return;

                          const regex = new RegExp("^[0-9]+$");

                          if (!regex.test(key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      <button
                        type="button"
                        onClick={getOtpCode}
                        disabled={checkDisabledButtonGetOtp()}
                      >
                        {!isTriggerTimer
                          ? t("button:GetOTP")
                          : _countdown === undefined
                          ? "05:00"
                          : String(_countdown)}
                      </button>
                    </div>
                  );
                }}
              />
            </Form.Item>

            <div>
              <Button
                disabled={!bank.bank_id || checkDisabledButtonAddBank()}
                loading={isLoading}
                css={BankMappingStyle.button}
                htmlType="submit"
              >
                {t("withdraw:AddBank")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <ModalSelectBank
        visible={openSelectBank}
        onClose={() => setOpenSelectBank(false)}
        currencyId={bank.bank_id}
        onSelectItem={(val) => {
          setBank(val);
          setOpenSelectBank(false);
        }}
        setBank={setBank}
        bankType={bankType}
      />
      {contextHolder}
    </Modal>
  );
};

export default ModalBankMapping;
