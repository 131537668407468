import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const ArrowCustomStyle = {
  index: css`
    cursor: pointer;
    pointer-events: visible;
    background-color: rgba(41, 133, 72, 0.65);
    transition: all 0.25s ease-in-out;
    width: 55px;
    height: 120px;
    svg {
      color: #f0f6ff;
      margin: auto;
      display: block;
      height: 100%;
      width: 60px;
    }
    &:hover {
      transition: all 0.25s ease-in-out;
      background-color: ${theme.colors.darkGreen};
    }
  `,
};
