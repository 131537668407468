export const getPlatform = () => {
  const userAgent: string =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  let deviceType = "Unknown Device";
  let isWebView = false;
  let isStandalone = false;

  // Kiểm tra chế độ Standalone trên iOS
  if ((window.navigator as any).standalone === true) {
    isStandalone = true; // iOS Standalone mode (Add to Home Screen)
  } else if (
    /wv/.test(userAgent) ||
    /Android.*Version\/[.0-9]*\sChrome\/[.0-9]*\sMobile\sSafari\/[.0-9]*$/.test(
      userAgent
    )
  ) {
    isWebView = true; // Android WebView
  } else if (
    /\(iP.+; CPU .*OS [0-9_]+.*\) AppleWebKit\/[0-9.]+ \(KHTML, like Gecko\) Mobile\/\w+/.test(
      userAgent
    ) &&
    !/Safari/.test(userAgent)
  ) {
    isWebView = true; // iOS WebView (not standalone)
  }

  // Kiểm tra loại thiết bị
  if (/android/i.test(userAgent)) {
    deviceType = "Android";
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    deviceType = "iOS";
  } else if (/Win/i.test(userAgent)) {
    deviceType = "Windows Desktop";
  } else if (/Mac/i.test(userAgent)) {
    deviceType = "MacOS Desktop";
  }

  if (deviceType === "iOS" && (isStandalone || isWebView)) {
    return 4;
  }
  if (deviceType === "Android" && isWebView) {
    return 3;
  }
  if (deviceType === "Android" || deviceType === "iOS") {
    return 2;
  }
  return 1;
};
