import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiMessageResponse, ApiResponse } from "types/api";
import {
  DepositOfflineSubmissionFormNews,
  DepositOnlineSubmissionFormNew,
  MessageType,
  ReceiveType,
} from "types/deposit";

import {
  DepositFormSettingResponse,
  DepositOfflineSubmissionForm,
  DepositOnlineSubmissionForm,
  DepositResponse,
  DepositSubmissionFormType,
} from "./type";

export const DepositApi = {
  getDepositForm: (username: string) =>
    axiosRequest.post<DepositFormSettingResponse>(
      "",
      payloadHelper("DepositForm", username, {
        username,
      })
    ),
  OnlineDepositSubmission: (
    typename: string,
    payload: DepositOnlineSubmissionForm
  ) =>
    axiosRequest.post<any>(
      "/",
      payloadHelper("GatewayDeposit", typename, payload)
    ),
  OnlineDepositSubmissionNew: (
    typename: string,
    payload: DepositOnlineSubmissionFormNew
  ) =>
    axiosRequest.post<any>(
      "/",
      payloadHelper("GatewayDeposit", typename, payload)
    ),
  depositSubmission: (
    typename: string,
    payload:
      | DepositOnlineSubmissionForm
      | DepositOnlineSubmissionFormNew
      | DepositOfflineSubmissionForm
      | DepositSubmissionFormType
  ) =>
    axiosRequest.post<DepositResponse>(
      "/",
      payloadHelper("Deposit", typename, payload)
    ),
  depositSubmissionOffline: (
    typename: string,
    payload: DepositOfflineSubmissionFormNews
  ) =>
    axiosRequest.post<DepositResponse>(
      "/",
      payloadHelper("Deposit", typename, payload)
    ),
  getMessageDeposit: (payload: MessageType) =>
    axiosRequest.post<ApiMessageResponse>(
      "/",
      payloadHelper("checkPopupOnOffDPS", payload.username, payload)
    ),
  getReceiveDataDPS: (payload: ReceiveType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("receiveDataDPS", payload.username, payload)
    ),
};
