import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse, ApiResponseMessageType } from "types/api";

import {
  MessagePayload,
  ReceiveWithdrawType,
  WithDrawalFormSettingTypeResponse,
  WithdrawalSubMissionForm,
} from "./type";

export const withdrawApi = {
  getWithdrawalForm: (username: string) =>
    axiosRequest.post<WithDrawalFormSettingTypeResponse>(
      "",
      payloadHelper("WithdrawForm", username, { username })
    ),
  withdrawalSubmission: (username: string, payload: WithdrawalSubMissionForm) =>
    axiosRequest.post<ApiResponse>(
      "",
      payloadHelper("Withdrawal", username, payload)
    ),

  getMessageWithdraw: (payload: MessagePayload) =>
    axiosRequest.post<ApiResponseMessageType>(
      "/",
      payloadHelper("checkPopupOnOffWRL", payload.username, payload)
    ),
  getReceiveDataWRL: (payload: ReceiveWithdrawType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("receiveDataWRL", payload.username, payload)
    ),
};
