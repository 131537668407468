import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import bnJson from "./translation/bn.json";
import enJson from "./translation/en.json";

export const resources = {
  en: enJson,
  bn: bnJson,
} as const;

i18n.use(initReactI18next).init({
  fallbackLng: "bn",
  debug: false,
  resources: {
    en: enJson,
    bn: bnJson,
  },
});

export { i18n };
