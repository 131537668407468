import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";
import { ApiResponse } from "types/api";

import {
  AffiliateRegisterFormType,
  ForgotPasswordFormType,
  LoginFormType,
  LoginResponse,
  RegisterFormType,
  RequestOTPType,
  VerifyTokenPayload,
} from "./type";

export const AuthApi = {
  login: (typename: string, payload: LoginFormType) =>
    axiosRequest.post<LoginResponse>(
      "/",
      payloadHelper("MemberLogin", typename, payload)
    ),
  register: (typename: string, payload: RegisterFormType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("MemberRegister", typename, payload)
    ),
  forgotPassword: (typename: string, payload: ForgotPasswordFormType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("ForgotPassword", typename, payload)
    ),
  requestOTP: (payload: RequestOTPType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("RequestOTP", payload.username, payload)
    ),
  affiliateRegister: (payload: AffiliateRegisterFormType) =>
    axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("AgentRegister", payload.username, payload)
    ),
  async verifyToken(typename: string, payload: VerifyTokenPayload) {
    const { data } = await axiosRequest.post<ApiResponse>(
      "/",
      payloadHelper("VerifyToken", typename, payload)
    );
    return data;
  },
};
