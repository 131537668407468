/** @jsxImportSource @emotion/react */

import LogoBaji from "assets/Common/icons/iplay_logo 2.png";
import IconCS24h from "assets/Common/icons/service-box/cs-247.webp";
import IconBoxBtn from "assets/Common/icons/service-box/cs-title-en.webp";
import IconScanQrCode from "assets/Common/icons/service-box/icon-code-black.webp";
import LogoFb from "assets/Common/icons/service-box/logo-fb.webp";
import LogoTelegram from "assets/Common/icons/service-box/logo-tele.webp";
import LogoWhatsaap from "assets/Common/icons/service-box/logo-whatsapp.webp";
import QrFb from "assets/Common/icons/service-box/qrcode_facebook.png";
import QrTele from "assets/Common/icons/service-box/qrcode_telegram.png";
import QrWhatsapp from "assets/Common/icons/service-box/qrcode_whatsapp.png";
import { useTranslation } from "react-i18next";

import { CustomerServiceBoxStyle } from "./style";

const dataListCS = [
  {
    id: "fb",
    name: "Facebook",
    link: "https://www.facebook.com/profile.php?id=61563479630876",
    icon_socail: LogoFb,
    icon_qr: QrFb,
  },
  {
    id: "ws",
    name: "Whatsapp CS",
    link: "http://wa.me/60184075619",
    icon_socail: LogoWhatsaap,
    icon_qr: QrWhatsapp,
  },
  {
    id: "tele",
    name: "Telegram",
    link: "https://t.me/baji568",
    icon_socail: LogoTelegram,
    icon_qr: QrTele,
  },
];

const CustomerServiceBox = () => {
  const { t } = useTranslation(["csBox"]);

  return (
    <div css={CustomerServiceBoxStyle.wrapper}>
      <div css={CustomerServiceBoxStyle.boxBtn} className="box-btn">
        <img src={IconBoxBtn} alt="icon" />
      </div>
      <div css={CustomerServiceBoxStyle.boxContent} className="box-content">
        <div className="icon-cs">
          <img src={IconCS24h} alt="..." />
        </div>
        <div className="logo">
          <img src={LogoBaji} alt="..." />
        </div>
        <ul className="list-cs-social">
          {dataListCS.map((item) => (
            <li key={item.id} className={`icon-${item.id}`}>
              <a href={item.link} target="_blank" rel="noreferrer">
                <img className="icon-social" src={item.icon_socail} alt="..." />
                <span>{t(item.name)}</span>
                <img className="icon-scan" src={IconScanQrCode} alt="..." />
              </a>
              <img className="cs-qrcode" src={item.icon_qr} alt="..." />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default CustomerServiceBox;
