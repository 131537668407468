import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const SideBarStyle = {
  container: () => css`
    width: 18rem;
    height: 100%;
    position: fixed;
    top: 0;
    left: -18rem;
    z-index: 99;
    overflow: auto;
    transition: all 0.5s ease;
    background-color: ${theme.colors.white};
  `,
  open: css`
    left: 0;
  `,
  overlay: css`
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 98;
  `,

  containerNotAuth: () => css`
    padding: 16px;
    .logoApp {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      img {
        max-width: 3rem;
        object-fit: contain;
      }
    }
    .listMenu {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      .item {
        padding: 12px 0;
        border-bottom: 1px solid ${theme.colors.gray[0]};
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        span:nth-of-type(1) {
          width: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        span:nth-of-type(2) {
          margin-left: 8px;
          font-weight: 400;
          font-size: 0.9rem;
          color: ${theme.colors.gray[300]};
        }
      }
    }
    .menuLogin {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      .login {
        background-color: ${theme.colors.darkGreen};
        color: ${theme.colors.white};
      }

      .register,
      .affiliateReg {
        margin-top: 8px;
      }

      .affiliateReg {
        background: #ddb247;
        color: #fff;
      }
    }
  `,
  containerAuth: () => css`
    padding: 16px;

    .affiliateReg {
      margin-top: 30px;
      width: 100%;
      background: #ddb247;
      color: #fff;
    }
  `,
  userInfo: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    img {
      width: 3rem;
      object-fit: contain;
    }
    span {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 8px;
      margin-right: 16px;
      font-weight: 500;
      color: ${theme.colors.gray[300]};
      font-size: 0.9rem;
    }
  `,
  ListMenu: css`
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    .item-menu {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      padding: 12px 0;
      border-bottom: 1px solid ${theme.colors.gray[0]};
      svg {
        width: 2rem;
      }
      span {
        margin-left: 8px;
        color: ${theme.colors.gray[300]};
      }
    }
  `,
  logout: css`
    display: flex;
    margin-top: 16px;
    flex-direction: column;

    button {
      background-color: ${theme.colors.gray[10]};
      color: ${theme.colors.white};
    }
  `,
  toAffPage: css`
    text-decoration: none;
    margin-top: 15px;
    font-size: 14px;
    text-align: center;
    border-top: 1px solid rgb(210 210 210 / 50%);
    padding-top: 5px;
    color: #298548;
    font-weight: 600;
    display: block;
  `,
};
