import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const CustomerServiceBoxStyle = {
  wrapper: css`
    position: fixed;
    top: 150px;
    width: 220px;
    right: -220px;
    z-index: 999;
    transition: all 0.3s ease;

    &:hover,
    &:active {
      right: 0;
    }

    ${media.md} {
      width: 170px;
      right: -170px;
      top: 320px;
    }
  `,
  boxBtn: css`
    position: absolute;
    top: 0;
    left: -35px;
    padding: 10px 5px;
    border: 0;
    border-radius: 5px 0 0 5px;
    background: #298548;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
    cursor: pointer;

    img {
      display: block;
      width: 25px;
      height: auto;
    }
  `,
  boxContent: css`
    width: 100%;
    position: absolute;
    top: 0;
    background: #3e3e3e;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .icon-cs,
    .logo {
      display: block;

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .logo img {
      max-width: 40%;
    }

    .list-cs-social {
      width: 100%;
      overflow: hidden;
      border-radius: 5px;
      background: #f5f5f5;
      color: #333;

      li {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        &:hover,
        &:active {
          background: rgba(18, 18, 18, 0.2);
          .cs-qrcode {
            height: 120px;
            margin-bottom: 10px;
          }
        }
      }

      li a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px 0 10px;
        text-decoration: none;
        width: 100%;

        .icon-social {
          width: 24px;
          height: 24px;
          margin-right: 5px;
        }

        .icon-scan {
          width: 15px;
          height: 15px;
          margin-left: 5px;
        }

        span {
          width: 67%;
          display: inline-block;
          text-align: center;
          padding: 8px 0;
          color: #333;
          font-size: 14px;
          font-weight: 500;
        }

        ${media.md} {
          .icon-social {
            width: 18px;
            height: 18px;
          }

          .icon-scan {
            width: 12px;
            height: 12px;
            margin-left: 5px;
          }

          span {
            font-size: 12px;
          }
        }
      }

      li .cs-qrcode {
        display: block;
        max-width: 60%;
        height: 0;
        margin: 0 auto;
        padding-bottom: 0;
        transition: height 0.5s ease;

        ${media.md} {
          display: none;
        }
      }
    }
  `,
};
