import { css } from "@emotion/react";
import { media } from "styles/breakpoints";
import { theme } from "styles/theme";

export const AffiliateRegisterModalStyle = {
  formWrapper: () => css`
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    margin-right: 32px;
    ${media["2sm"]} {
      margin: 0 16px;
    }
    .referral-code {
      text-align: center;
    }
  `,
  btnRegister: css`
    height: 44px;
    background-color: ${theme.colors.darkGreen};
    color: ${theme.colors.white};
    border-radius: 8px;
    border: 0px;
    margin-top: 16px;
    transition: all 0.3s ease-in-out;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    margin-bottom: 20px;

    &:disabled {
      background: ${theme.colors.gray[150]};
      color: ${theme.colors.white};
      cursor: no-drop;
    }
  `,
};
