export const isMobile = /iPhone|iPad|iPod|Android|tablet/i.test(
  navigator.userAgent
);

export const FORMAT_DATE = "DD/MM/YYYY hh:mm";

export const URL_DOWNLOAD_IOS = `${window.location.origin}/app/baji568.mobileconfig`;

export const URL_DOWNLOAD_ANDROID = `${window.location.origin}/app/baji568.apk`;

export const URL_PICTURE_BANK = "https://baji568.com/uploads/banks/";

export const SysLangLocal = "syslang";
export const i18nLngLocal = "i18nLng";
