/** @jsxImportSource @emotion/react */

import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, ConfigProvider, Form, Modal, UploadFile } from "antd";
import { DepositApi } from "api/deposit";
import { DepositSubmissionFormTypeMapping } from "api/deposit/type";
import CloseIcon from "assets/temporary/icons/Close.png";
import { ReactComponent as RightIcon } from "assets/temporary/icons/right_icon.svg";
import { ReactComponent as WalletIcon } from "assets/temporary/icons/wallet.svg";
import DatePickerCustom from "components/Common/DatePickerCustom";
import InputCustom from "components/Common/InputCustom";
import InputFile from "components/Common/InputFile";
import { ResponseCode } from "contants/response";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useVerify } from "hooks/useVerify";
import { i18n } from "i18n";
import _isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { selectUsername } from "store/auth";
import {
  initialState,
  selectDataDeposit,
  selectIsLoadingDeposit,
} from "store/deposit";
import { getDepositForm } from "store/deposit/deposit.thunk";
import { selectOpenModal, setOpenModal } from "store/modal";
import { resetDepositOrders } from "store/orders";
import { selectProfile } from "store/user";
import { theme } from "styles/theme";
import { StatusNotification } from "types";
import { ChannelOnlineType } from "types/deposit";
import * as yup from "yup";

import { ModalDepositStyle } from "./index.style";
import { ModalBanking } from "./ModalBanking";
import { ModalChannel } from "./ModalChannel";
import { ModalCurrency } from "./ModalCurrency";
import { ModalDepositForm } from "./ModalDepositForm";
import { ModalGateway } from "./ModalGateway";
import { ModalPromotion } from "./ModalPromotion";
import { ModalTransaction } from "./ModalTransaction";
interface Props {
  openNotification: (status: StatusNotification, message: string) => void;
}

const dateTimeDefault = new Date();

export const ModalDeposit = (props: Props) => {
  const { openNotification } = props;

  useVerify();

  const dispatch = useAppDispatch();
  const dataDeposit = useAppSelector(selectDataDeposit);
  const username = useAppSelector(selectUsername);
  const openModal = useAppSelector(selectOpenModal);
  const isLoading = useAppSelector(selectIsLoadingDeposit);
  const profile = useAppSelector(selectProfile);

  const {
    currencies,
    offline_banking_channel,
    online_banking_channel,
    promotion,
  } = dataDeposit;

  const [isOpenModalCurrencies, setIsOpenModalCurrencies] =
    useState<boolean>(false);
  const [isOpenModalDeposit, setIsOpenModalDeposit] = useState<boolean>(false);
  const [isOpenModalPromotion, setIsOpenModalPromotion] =
    useState<boolean>(false);
  const [isOpenModalBank, setIsOpenModalBank] = useState<boolean>(false);
  const [isOpenModalTransaction, setIsModalTransction] =
    useState<boolean>(false);
  const [isOpenModalGateway, setIsOpenModalGateway] = useState<boolean>(false);
  const [isOpenModalChannel, setIsOpenModalChannel] = useState<boolean>(false);

  const [amount, setAmount] = useState<number | null>(null);
  const [type, setType] = useState<number>(1);
  const [typeName, setTypeName] = useState<string>("Offline Deposit");
  const [currencyId, setCurrencyId] = useState<string>("1");
  const [currencyName, setCurrencyName] = useState<string>("");
  const [bank_account_id, setBank_account_id] = useState<string>("1");
  const [bank_name, setBank_name] = useState<string>("");
  const [pictureBanking, setPictureBanking] = useState<string>("");
  const [promotionId, setPromotionId] = useState<string>("0");
  const [promotionName, setPromotionName] = useState<string>("");
  const [minDeposit, setMinDeposit] = useState<number>(0);
  const [maxDeposit, setMaxDeposit] = useState<number>(0);
  const [channels, setChannels] = useState<ChannelOnlineType[]>([]);
  const [bankSlip, setBankSlip] = useState<string>("");
  const [dateTime, setDateTime] = useState<string>("");

  const [gateway, setGateway] = useState<string>("");
  const [gatewayPicture, setGatewayPicture] = useState<string>("");
  const [channelCode, setChannelCode] = useState<string>("");
  const [channelName, setChannelName] = useState<string>("");

  const isDataDepositEmpty = _isEqual(initialState.dataDeposit, dataDeposit);

  const schema = yup.object({
    type: yup.number().required(i18n.t("validate:FieldRequired")),
    amount: yup
      .number()
      .max(maxDeposit, `${i18n.t("deposit:MaxDeposit")} ${maxDeposit}`)
      .min(minDeposit, `${i18n.t("deposit:MinDeposit")} ${minDeposit}`)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(i18n.t("validate:FieldRequired")),
    file: yup
      .mixed()
      .test(
        "empty",
        "File is required",
        (file: any, testContext: yup.TestContext<yup.AnyObject>): boolean => {
          if (testContext.parent.type === 2) return true;
          return !!file;
        }
      ),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<DepositSubmissionFormTypeMapping>({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      date_time: dayjs(dateTimeDefault),
      type: 1,
    },
  });

  useEffect(() => {
    if (!username) return;

    if (!isDataDepositEmpty) return;
    dispatch(getDepositForm(username));
  }, [username]);

  useEffect(() => {
    if (type === 2) return;
    if (offline_banking_channel.length === 0) return;

    setMaxDeposit(Number(offline_banking_channel[0].max_deposit));
    setMinDeposit(Number(offline_banking_channel[0].min_deposit));
    setPictureBanking(offline_banking_channel[0].picture);
  }, [type, offline_banking_channel]);

  useEffect(() => {
    if (type === 1) return;
    if (online_banking_channel.length === 0) return;

    setGateway(online_banking_channel[0].gateway);
    setChannels(online_banking_channel[0].channel);
    setMaxDeposit(Number(online_banking_channel[0].max_deposit));
    setMinDeposit(Number(online_banking_channel[0].min_deposit));
    setGatewayPicture(online_banking_channel[0].picture);

    if (online_banking_channel[0].channel.length === 0) return;
    setChannelCode(online_banking_channel[0].channel[0].code);
    setChannelName(online_banking_channel[0].channel[0].name);
  }, [type, online_banking_channel]);

  const depositForm = (data: DepositSubmissionFormTypeMapping) => {
    setIsModalTransction(true);
    setAmount(data.amount);
    setDateTime(dayjs(data.date_time).format("YYYY-MM-DD hh:mm:ss"));
  };

  const handleCloseModal = () => {
    reset();
    setAmount(0);
    setTypeName("");
    setType(0);
    setCurrencyName("");
    setCurrencyId("1");
    setBank_account_id("1");
    setBank_name("");
    setPictureBanking("");
    dispatch(setOpenModal(""));
  };

  const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      if (file) {
        fileReader.readAsDataURL(file);
      }
      fileReader.onload = () => {
        resolve(fileReader?.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFile = (file: UploadFile<any>) => {
    convertBase64(file).then((data: unknown) => {
      setBankSlip(data as string);
    });
  };

  const onSubmitOffline = async () => {
    if (!amount) return;

    const prefix = "\\";
    const bank_slip = prefix + bankSlip.split(",")[1];

    const response = await DepositApi.depositSubmission(username, {
      amount,
      promotion_id: Number(promotionId),
      bank_account_id: Number(bank_account_id),
      currency_id: Number(currencyId),
      username,
      date_time: dateTime,
      bank_slip,
      type: 1,
    });

    const { status } = response.data;

    if (status !== ResponseCode.SUCCESS) {
      openNotification("error", "Add deposit failed!!!");
      return;
    }

    openNotification("success", "Add deposit success!!!");

    setIsModalTransction(false);
    dispatch(setOpenModal(""));

    reset();
    setAmount(0);
    setType(0);
    setCurrencyId("1");
    setBank_account_id("1");
    setTypeName("");
    setCurrencyName("");
    setBank_name("");
    setPictureBanking("");

    dispatch(resetDepositOrders());
    dispatch(getDepositForm(username));
  };

  const onSubmitOnline = async () => {
    if (!amount) return;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
    let openBlank = window.open("", "DepositOnline");

    const response = await DepositApi.OnlineDepositSubmission(username, {
      amount,
      gateway,
      username,
      channel: channelCode,
      promotion_id: Number(promotionId),
    });

    const { status, data: url } = response.data;

    if (status !== ResponseCode.SUCCESS) {
      openNotification("error", "Add deposit failed!!!");
      openBlank?.close();
      return;
    }

    (openBlank?.location as { href: string }).href = url;

    setIsModalTransction(false);
    dispatch(setOpenModal(""));

    reset();
    setChannelCode("");
    setChannelName("");
    setGatewayPicture("");
    setChannels([]);
    setAmount(0);
    setType(0);

    dispatch(resetDepositOrders());
    dispatch(getDepositForm(username));
  };

  const handleSubmitDeposit = () => {
    if (type === 1) {
      onSubmitOffline();
      return;
    }

    onSubmitOnline();
  };

  return (
    <>
      <Modal
        open={openModal.includes("deposit")}
        onCancel={handleCloseModal}
        centered
        footer={null}
        css={ModalDepositStyle.index}
        closable={false}
      >
        <div>
          <div css={ModalDepositStyle.backgroundHeader}>
            <div css={ModalDepositStyle.header}>
              <p css={ModalDepositStyle.title}>Deposit</p>
              <Button
                onClick={handleCloseModal}
                css={ModalDepositStyle.buttonClose}
              >
                <img src={CloseIcon} alt="" />
              </Button>
            </div>
            <div css={ModalDepositStyle.content}>
              <div
                css={ModalDepositStyle.form}
                style={{
                  height: type === 1 ? "450px" : "400px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <WalletIcon height={24} width={24} />
                    <div
                      style={{
                        marginLeft: "4px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          paddingTop: "4px",
                        }}
                      >
                        Current balance
                      </span>
                    </div>
                  </div>

                  <span
                    style={{
                      paddingTop: "4px",
                      fontWeight: 700,
                    }}
                  >
                    {profile?.points || "0.00"} BDT
                  </span>
                </div>

                <div>
                  <Form
                    className="deposit_form"
                    onSubmitCapture={handleSubmit(depositForm)}
                  >
                    <div
                      style={{
                        marginTop: "20px",
                        display: "grid",
                        gridTemplateColumns: "repeat(5, 1fr)",
                        gap: 8,
                      }}
                    >
                      <div
                        style={{
                          gridColumn: "1 / 4",
                        }}
                      >
                        <InputCustom
                          control={control}
                          name="amount"
                          errors={errors.amount?.message}
                          validate_status={errors.amount && "error"}
                          placeholder="Amount"
                        />
                      </div>

                      {type === 1 ? (
                        <div
                          css={ModalDepositStyle.currency}
                          onClick={() => setIsOpenModalCurrencies(true)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <p>
                              {currencyName ||
                                (currencies.length > 0 &&
                                  currencies[0].currency_name)}
                            </p>
                            <div>
                              <RightIcon />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          css={ModalDepositStyle.currency}
                          onClick={() => setIsOpenModalGateway(true)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <p>{gateway}</p>
                            <div>
                              <RightIcon />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      css={ModalDepositStyle.depositType}
                      onClick={() => setIsOpenModalDeposit(true)}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p>{typeName || "Choose from deposit"}</p>
                        <div>
                          <RightIcon />
                        </div>
                      </div>
                    </div>

                    {type === 1 ? (
                      <div
                        css={ModalDepositStyle.depositType}
                        onClick={() => setIsOpenModalBank(true)}
                        style={{
                          cursor: type ? "pointer" : "default",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <p>
                            {bank_name ||
                              (type === 1 &&
                                offline_banking_channel.length > 0 &&
                                offline_banking_channel[0].bank_name) ||
                              "Choose receiving bank"}
                          </p>
                          <div>
                            <RightIcon />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        css={ModalDepositStyle.depositType}
                        onClick={() => type && setIsOpenModalChannel(true)}
                        style={{
                          cursor: type ? "pointer" : "default",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <p>{channelName || "Choose from channel"}</p>
                          <div>
                            <RightIcon />
                          </div>
                        </div>
                      </div>
                    )}

                    {type === 1 && (
                      <>
                        <div
                          css={css`
                            flex: 1;
                            border-radius: 10px;
                            margin-bottom: ${errors.file?.message
                              ? "10px"
                              : "20px"};
                          `}
                        >
                          <InputFile
                            error={errors.file?.message as string}
                            control={control}
                            name="file"
                            file={watch("file")}
                            validate_status={errors.file ? "error" : "success"}
                            onChange={(file) => handleFile(file)}
                            placeholder="Upload bank slip"
                            isDeposit
                          />
                        </div>

                        <div
                          css={css`
                            margin-bottom: 20px;
                          `}
                        >
                          <DatePickerCustom
                            defaultValue={watch("date_time")}
                            control={control}
                            name="date_time"
                            picker="date"
                            validate_status={errors.date_time && "error"}
                            error={errors.date_time?.message as any}
                            placeholder="Choose date time"
                            showToday={false}
                            format={"DD/MM/YYYY"}
                          />
                        </div>
                      </>
                    )}

                    <div
                      css={ModalDepositStyle.depositType}
                      onClick={() => type && setIsOpenModalPromotion(true)}
                      style={{
                        cursor: type ? "pointer" : "default",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <p>{promotionName || "Choose from promotion"}</p>
                        <div>
                          <RightIcon />
                        </div>
                      </div>
                    </div>

                    <ConfigProvider
                      theme={{
                        token: {
                          colorBgContainerDisabled: theme.colors.gray[150],
                          colorTextDisabled: theme.colors.white,
                        },
                      }}
                    >
                      <Button
                        css={ModalDepositStyle.button}
                        htmlType="submit"
                        disabled={!type}
                        style={{
                          pointerEvents: !type ? "none" : "unset",
                          border: "transparent",
                          marginTop: type === 1 ? "40px" : 0,
                        }}
                        loading={isLoading}
                      >
                        Continue
                      </Button>
                    </ConfigProvider>
                  </Form>
                </div>
              </div>
            </div>
          </div>
          <div
            css={ModalDepositStyle.backgroundBottom}
            style={{
              height: "550px",
            }}
          />
        </div>
      </Modal>

      <ModalCurrency
        currencies={currencies}
        currencyId={currencyId}
        isOpenModalCurrencies={isOpenModalCurrencies}
        setCurrencyId={setCurrencyId}
        setCurrencyName={setCurrencyName}
        setIsOpenModalCurrencies={setIsOpenModalCurrencies}
      />

      <ModalPromotion
        promotions={promotion}
        promotionId={promotionId}
        isOpenModalPromotion={isOpenModalPromotion}
        setPromotionId={setPromotionId}
        setPromotionName={setPromotionName}
        setIsOpenModalPromotion={setIsOpenModalPromotion}
      />

      <ModalChannel
        channelCode={channelCode}
        channels={channels}
        isOpenModalChannel={isOpenModalChannel}
        setCodeChannel={setChannelCode}
        setIsOpenModalChannel={setIsOpenModalChannel}
        setNameChannel={setChannelName}
      />

      <ModalDepositForm
        isOpenModalDeposit={isOpenModalDeposit}
        setValue={setValue}
        offline_banking_channel={offline_banking_channel}
        setBank_account_id={setBank_account_id}
        setBank_name={setBank_name}
        setIsOpenModalDeposit={setIsOpenModalDeposit}
        setPictureBanking={setPictureBanking}
        setType={setType}
        setTypeName={setTypeName}
        type={type}
        setMaxDeposit={setMaxDeposit}
        setMinDeposit={setMinDeposit}
      />

      <ModalBanking
        bank_account_id={bank_account_id}
        isOpenModalBank={isOpenModalBank}
        offline_banking_channel={offline_banking_channel}
        setBank_account_id={setBank_account_id}
        setBank_name={setBank_name}
        setIsOpenModalBank={setIsOpenModalBank}
        setPictureBanking={setPictureBanking}
      />

      <ModalGateway
        gateWay={gateway}
        isOpenModalGateway={isOpenModalGateway}
        online_banking_channel={online_banking_channel}
        setGateway={setGateway}
        setIsOpenModalGateWay={setIsOpenModalGateway}
        setPictureGateway={setGatewayPicture}
        setChannels={setChannels}
      />

      <ModalTransaction
        bank_name={bank_name}
        isOpenModalTransaction={isOpenModalTransaction}
        setIsModalTransction={setIsModalTransction}
        onSubmit={handleSubmitDeposit}
        pictureBanking={pictureBanking}
        amount={amount || null}
        gatewayPicture={gatewayPicture}
        type={type}
        gateway={gateway}
      />
    </>
  );
};
