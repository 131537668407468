import { Helmet } from "react-helmet";

type Props = {
  title: string | undefined;
};

function HelmetCustom(props: Props) {
  const { title = "" } = props;
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title + " - baji568"}</title>
      <meta
        name="description"
        content="baji568 is a trusted online casino in Malaysia where you can play Slots, Sports Betting, Baccarat, Blackjack, Roulette and more online casino games. Register an account and start playing. Welcome Bonus and Daily Bonus ready to claim"
      />
      <link
        rel="icon"
        type="image/png"
        href="https://baji568.com/favicon.ico"
      />
    </Helmet>
  );
}

export default HelmetCustom;
