import { useCallback, useEffect, useState } from "react";

export const useCountDownTime = (
  timeCountDown: number,
  isTriggerTimer: boolean
) => {
  const [time, setTime] = useState<number>(timeCountDown);
  const [isCounting, setIsCounting] = useState<boolean>(false);

  useEffect(() => {
    if (!isTriggerTimer) {
      if (isCounting) {
        setIsCounting(false);
      }
      return;
    }

    setIsCounting(true);

    const timer = setInterval(() => {
      if (time > 0) {
        setTime((prev) => prev - 1);
        return;
      }

      clearInterval(timer);
      setIsCounting(false);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [time, isTriggerTimer, isCounting]);

  const countDown = useCallback(() => {
    if (!isTriggerTimer) return "00:00";

    const minutes = Math.floor(time / 60);
    const second = time % 60;

    return `0${minutes}:${second < 10 ? 0 : ""}${second}`;
  }, [time, isTriggerTimer]);

  return [countDown(), setTime, time, isCounting];
};
