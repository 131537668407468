import { css } from "@emotion/react";
import { theme } from "styles/theme";

export const OurPartnerStyle = {
  index: css`
    margin-top: 80px;
  `,
  label: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    h3 {
      color: #440609;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    span {
      color: ${theme.colors.lightText};
      font-size: 14px;
      font-weight: 600;
      text-decoration-line: underline;
      cursor: pointer;
    }
  `,
  boxSwiper: css`
    border-radius: 12px;
    background: #f2f6f2;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
    margin-top: 8px;
  `,
  slider: css`
    .slick-slide {
      width: 144px !important;
      margin: 16px 12px 16px 12px;
    }
  `,
  itemPartner: css`
    width: calc(100% - 24px) !important;
    margin: 16px 12px;

    img {
      width: 100%;
      height: 100%;
    }
  `,
};
