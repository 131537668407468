import { css, Theme } from "@emotion/react";

export const UserMenuSidebarStyle = {
  self: ({ colors }: Theme) => css`
    min-width: 280px;
    border-radius: 10px;
    background-color: ${colors.white};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    height: fit-content;
  `,

  wallet: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border-bottom: 1px solid rgba(175, 175, 181, 0.2);
  `,

  walletUsername: ({ colors }: Theme) => css`
    color: ${colors.bank[1]};
    font-size: 16px;
    font-weight: 700;
  `,

  walletValue: ({ colors }: Theme) => css`
    color: ${colors.black};
    font-size: 20px;
    font-weight: 700;

    .ant-skeleton-title {
      height: 27px !important;
      width: 106.63px !important;
    }
  `,

  list: css`
    display: flex;
    justify-content: center;
    align-items: center;
  `,

  balance: (isLoadingBalance: boolean) => css`
    margin-left: 10px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${isLoadingBalance ? "default" : "pointer"};
  `,

  menuItem: (active: boolean) => css`
    padding: 8px 16px;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: ${active ? "rgba(22, 189, 69, 0.05)" : "transparent"};
    flex: 1;
    cursor: pointer;
    &:hover {
      background: rgba(22, 189, 69, 0.05);
    }
    svg {
      width: 24px;
      height: 24px;
    }
    .icon-image {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  `,

  menuLabel: css`
    color: #1c1c1e;
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
  `,

  buttonContainer: css`
    padding: 0px 16px 8px 16px;
  `,

  btnLogout: ({ colors }: Theme) => css`
    height: 34px !important;
    margin-top: 6px;
    background-color: #f5f5f5;
    box-shadow: none !important;
    color: ${colors.darkGreen};
    font-size: 13px;
    font-weight: 600;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    & .ant-wave {
      color: ${colors.darkGreen};
    }
    &:hover,
    &:active {
      background-color: #f5f5f5 !important;
      color: ${colors.darkGreen} !important;
    }
  `,
};
