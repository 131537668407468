import { css } from "@emotion/react";
import { media } from "styles/breakpoints";

export const AffiliatePageStyle = {
  section: css`
    padding-top: 75px;
    min-width: 1200px;

    ${media.md} {
      min-width: 100%;
      padding-top: 25px;
      padding: 8px;
    }
  `,
  wrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 46px;
    width: 1200px;
    margin: 0 auto;

    ${media.md} {
      padding-top: 25px;
      width: 100%;

      .table-inner {
        overflow-x: auto;
        table {
          min-width: 700px;
        }
      }
    }

    .title {
      color: #298548;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 12px;
      text-align: center;

      ${media.md} {
        line-height: 1;
      }
    }

    .group-notice {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .notice-title {
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: underline;
        color: #298548;
        margin-bottom: 5px;
      }

      p {
        font-size: 16px;
        font-style: italic;
        padding-left: 20px;
      }

      ${media.md} {
        p {
          font-size: 14px;
          padding-left: 10px;
        }
      }
    }

    .note {
      color: #ff2a2a;
      font-size: 16px;
      font-style: italic;
      font-weight: 400;

      ${media.md} {
        font-size: 14px;
      }
    }
  `,
  wrapInfo: css`
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 70%;
    align-items: center;

    ${media.md} {
      width: 100%;
    }

    .row-info {
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      gap: 16px;

      ${media.md} {
        flex-wrap: wrap;
      }
    }

    .wrap-item {
      width: 100%;
      padding: 20px;
      background: linear-gradient(194deg, #1fa94d 9.53%, #298548 90.21%);
      border-radius: 16px;
      height: 200px;

      .subtitle {
        color: #f3e2a4;
        text-transform: uppercase;
        font-size: 22px;
        font-weight: 500;
      }

      .desc {
        text-transform: uppercase;
        color: #fff;
        font-size: 22px;
        line-height: 22px;
        margin-top: 20px;
      }
    }
  `,
  wrapBtnReg: css`
    margin-top: 80px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .btnRegisterAff {
      border: none;
      font-weight: 600;
      cursor: pointer;
      text-transform: uppercase;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 25px;
      background: linear-gradient(194deg, #1fa94d 9.53%, #298548 90.21%);
      padding: 15px 50px;
      border-radius: 50px;
      box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.4);

      svg {
        width: 60px;
        height: 60px;
        margin-right: 15px;

        path {
          fill: #fff;
        }
      }

      ${media.sm} {
        padding: 15px 40px;
        font-size: 22px;
        white-space: nowrap;
        max-width: 100%;

        svg {
          width: 35px;
          height: 35px;
        }
      }
    }

    .href-link {
      color: #298548;
      font-size: 20px;
      font-weight: 500;
      text-transform: uppercase;

      ${media.sm} {
        font-size: 18px;
      }
    }
  `,
  wrapTable: css`
    margin-top: 100px;
    width: 100%;

    table {
      margin-top: 40px;
      width: 100%;
      border-radius: 16px;
      border-collapse: separate;
      border-spacing: 0 15px;
      font-size: 16px;

      thead {
        background: linear-gradient(194deg, #1fa94d 9.53%, #298548 90.21%);
        color: #fff;
        height: 42px;
        font-size: 14px;

        tr {
          text-transform: uppercase;
        }

        ${media.md} {
          th {
            background: #268e48;
          }
        }
      }

      tfoot {
        background: linear-gradient(194deg, #298548 9.53%, #24693b 90.21%);
        color: #ffff;
        font-size: 16px;
        height: 42px;

        tr {
          text-transform: uppercase;
          font-weight: 700;
        }

        ${media.md} {
          font-size: 14px;
          th {
            background: #236d3c;
          }
        }
      }

      td,
      th {
        padding: 15px 16px;
        text-align: center;
        font-weight: 600;
        white-space: nowrap;

        ${media.md} {
          &:first-of-type {
            position: sticky;
            left: 0;
            z-index: 0;
            box-shadow: 6px 0px 10px rgba(0, 0, 0, 0.1);
          }
        }
      }

      .down {
        color: #d50101;
      }

      tbody {
        font-size: 16px;
        tr {
          background: linear-gradient(
            194deg,
            #07572185 9.53%,
            #45ca724f 90.21%
          );
          color: #000000;
          font-weight: 600;

          td:first-of-type {
            font-size: 18px;
          }
        }

        ${media.md} {
          font-size: 13px;
          td {
            background: #b8e4c8;
          }

          tr td:first-of-type {
            font-size: 16px;
          }
        }
      }
    }
  `,
  wrapT_C: css`
    margin-top: 50px;
    width: 100%;

    .wrap-term {
      margin-top: 50px;

      .subtitle {
        color: #298548;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px;
      }

      p {
        font-size: 16px;
        padding-left: 10px;
        margin-bottom: 10px;
      }

      span {
        display: inline-block;
        padding-left: 25px;
        margin-bottom: 10px;
      }
    }
  `,
};
