/* eslint-disable prefer-const */
import { AnyAction, Dispatch } from "@reduxjs/toolkit";
import { GameApi } from "api/game";
import axios, { CancelTokenSource } from "axios";
import { closeBlankWindow, showBlankWindow } from "contants/openBlankWindow";
import { ResponseCode } from "contants/response";
// import { useEffect, useState } from "react";
import { updateNameLoginGame } from "store/game";
import { setOpenModal } from "store/modal";
import { StatusNotification } from "types";
import { SubGameType } from "types/game";

let source: CancelTokenSource;

interface GamePromoResponse {
  status: number;
  msg: string;
  // Add other properties if needed
}

export const handleLoginGame = async (
  dataLogin: SubGameType,
  username: string,
  dispatch: Dispatch<AnyAction>,
  openNotification: (status: StatusNotification, message: string) => void
) => {
  if (!username) {
    dispatch(setOpenModal("signin"));
    return;
  }

  closeBlankWindow();
  const openBlank = showBlankWindow("LoginGame");

  try {
    const response = await GameApi.checkGamePromoOnOff(username);
    const res: GamePromoResponse = response.data;
    if (res.status === 0 && dataLogin.type !== "SB") {
      openNotification("error", res.msg);
      return;
    } else if (res.status === 1 && dataLogin.type !== "SL") {
      openNotification("error", res.msg);
      return;
    }
  } catch (error) {
    if (
      (error as { message: string }).message.includes(
        "Operation canceled due to new request."
      )
    ) {
      return;
    }
    closeBlankWindow();
    openNotification("error", "Invalid Error");
    dispatch(updateNameLoginGame(""));
  }

  if (source) {
    source.cancel("Operation canceled due to new request.");
  }

  source = axios.CancelToken.source();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, prefer-const
  // let openBlank = window.open("", "LoginGame");

  // if (!openBlank) return;

  // let loaderElement = openBlank.document.createElement("div");
  // const style = openBlank.document.createElement("style");

  // loaderElement.className = "loader";

  // style.textContent = `
  // .loader { height:100%;width:100%;display:flex;justify-content:center;align-items:center;position:fixed;top:0;left:0;background:#333333;}
  // .loader::after{content:"";width:75px;height:75px;border:15px solid #dddddd;border-top-color:#298548;border-radius:50%;animation:loading 2s ease infinite}
  // @keyframes loading {
  //   from { transform: rotate(0turn)}
  //   to { transform:rotate(1turn)}
  // }
  // `;

  // dispatch(updateNameLoginGame(openBlank.name));
  // openBlank.document.body.appendChild(loaderElement);
  // openBlank.document.head.appendChild(style);
  dispatch(updateNameLoginGame(openBlank.name));

  try {
    const response = await GameApi.loginGame(username, {
      username,
      game_type: dataLogin.type,
      game_code: dataLogin.game_code,
      provider: dataLogin.provider_code,
      source,
    });

    const dataLoginGame = response.data;

    if (dataLoginGame.status !== ResponseCode.SUCCESS) {
      openNotification("error", dataLoginGame.msg);
      closeBlankWindow();
      dispatch(updateNameLoginGame(""));
      return;
    }

    openNotification("success", dataLoginGame.msg);

    // (openBlank?.location as { href: string }).href = dataLoginGame.url;
    // (showBlankWindow("LoginGame")?.location as { href: string }).href =
    //   dataLoginGame.url;
    (openBlank?.location as { href: string }).href = dataLoginGame.url;
  } catch (error) {
    if (
      (error as { message: string }).message.includes(
        "Operation canceled due to new request."
      )
    ) {
      return;
    }
    closeBlankWindow();
    openNotification(
      "error",
      "Server is under maintenance, please try again later!"
    );
    dispatch(updateNameLoginGame(""));
  }
};
