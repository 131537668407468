/** @jsxImportSource @emotion/react */

import { SyncOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, notification } from "antd";
import { AuthApi } from "api/auth";
import { AffiliateRegisterFormType } from "api/auth/type";
import backgroundLogin from "assets/temporary/images/list_bg_2.jpg";
import InputCustom from "components/Common/InputCustom";
import InputPassword from "components/Common/InputPassword";
import { ModalCustom } from "components/Common/ModalCustom";
import {
  REGEX_EMAIL,
  REGEX_PASSWORD,
  REGEX_USERNAME_AGENT,
} from "contants/regex";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { selectOpenModal, setOpenModal } from "store/modal";
import { selectProfile } from "store/user";
import { StatusNotification } from "types";
import { ApiResponse } from "types/api";
import * as yup from "yup";

import { LogoAuth } from "../LogoAuth";
import { AffiliateRegisterModalStyle } from "./index.style";

const AffiliateRegisterModal = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation([
    "header",
    "placeholder",
    "validate",
    "button",
    "notification",
  ]);

  const openModal = useAppSelector(selectOpenModal);
  const profile = useAppSelector(selectProfile);

  const handleCloseModal = () => {
    dispatch(setOpenModal(""));
  };

  const [api, contextHolder] = notification.useNotification();
  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
    });
  };

  const schema = yup
    .object({
      username: yup
        .string()
        .trim()
        .required(t("validate:FieldRequired"))
        .matches(REGEX_USERNAME_AGENT, t("validate:UsernameValid")),
      password: yup
        .string()
        .trim()
        .required(t("validate:FieldRequired"))
        .matches(REGEX_PASSWORD, t("validate:PasswordValid")),
      fullname: yup.string().trim().required(t("validate:FieldRequired")),
      mobile: yup
        .string()
        .trim()
        .required(t("validate:FieldRequired"))
        .min(10, t("validate:Min"))
        .max(14, t("validate:Max")),
      email: yup
        .string()
        .required(t("validate:FieldRequired"))
        .matches(REGEX_EMAIL, t("validate:EmailRequired")),
      whatsapp: yup
        .string()
        .trim()
        .required(t("validate:FieldRequired"))
        .max(14, t("validate:Max")),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<AffiliateRegisterFormType>({
    resolver: yupResolver<any>(schema),
    mode: "all",
  });

  const onSubmit = async (data: AffiliateRegisterFormType) => {
    try {
      const dataForm = {
        ...data,
        player_id: profile?.player_id ?? null,
      };

      setIsLoading(true);
      const res = await AuthApi.affiliateRegister(dataForm);
      setIsLoading(false);
      const { msg, status }: ApiResponse = res.data;
      if (status !== ResponseCode.SUCCESS) {
        openNotification("error", msg);
        return;
      } else {
        openNotification("success", t("notification:SuccessAgentRegister"));
        setTimeout(() => {
          dispatch(setOpenModal(""));
        }, 2000);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  };

  return (
    <>
      <ModalCustom
        isOpenModal={openModal.includes("affiliateRegister")}
        handleCloseModal={handleCloseModal}
        titleModal={t("AFFRegister")}
        headerBackground={backgroundLogin}
        heightBackground={75}
        bodyModal={
          <div style={{ overflow: "auto" }}>
            <LogoAuth />
            <Form
              css={AffiliateRegisterModalStyle.formWrapper()}
              autoComplete="off"
              onSubmitCapture={handleSubmit(onSubmit)}
            >
              <InputCustom
                disabled={isLoading}
                placeholder={t("placeholder:AgentName")}
                name={"username"}
                control={control}
                errors={errors.username?.message}
                margin_bottom={"8px"}
                validate_status={errors.username ? "error" : "success"}
                maxLength={16}
                autoComplete="off"
              />
              <InputPassword
                disabled={isLoading}
                placeholder={t("placeholder:Password")}
                name={"password"}
                control={control}
                margin_bottom="8px"
                error={errors.password?.message}
                validate_status={errors.password ? "error" : "success"}
              />
              <InputCustom
                placeholder={t("placeholder:FullName")}
                name={"fullname"}
                control={control}
                margin_bottom={"8px"}
                disabled={isLoading}
                errors={errors.fullname?.message}
                validate_status={errors.fullname ? "error" : "success"}
                onKeyDown={(e) => {
                  const key = e.key;

                  const regex = new RegExp("^[a-zA-Z\\s]+$");

                  if (!regex.test(key) && key !== "Enter") {
                    e.preventDefault();
                  }
                }}
              />
              <InputCustom
                placeholder={t("placeholder:PhoneNumber")}
                name={"mobile"}
                type="number"
                control={control}
                margin_bottom={"8px"}
                errors={errors.mobile?.message}
                validate_status={errors.mobile ? "error" : "success"}
                disabled={isLoading}
              />
              <InputCustom
                placeholder={t("placeholder:Email")}
                name={"email"}
                control={control}
                margin_bottom={"8px"}
                errors={errors.email?.message}
                validate_status={errors.email ? "error" : "success"}
                disabled={isLoading}
              />
              <InputCustom
                placeholder={t("placeholder:WhatsAppLink")}
                name={"whatsapp"}
                type="number"
                control={control}
                margin_bottom={"8px"}
                errors={errors.whatsapp?.message}
                validate_status={errors.whatsapp ? "error" : "success"}
                disabled={isLoading}
              />
              <button
                css={AffiliateRegisterModalStyle.btnRegister}
                type="submit"
                disabled={isLoading || !isValid}
              >
                {isLoading && (
                  <SyncOutlined spin style={{ marginRight: "10px" }} />
                )}
                {t("button:Register")}
              </button>
            </Form>
          </div>
        }
      />
      {contextHolder}
    </>
  );
};
export default AffiliateRegisterModal;
