import { css } from "@emotion/react";
import { CategoryGameType } from "contants/menu-game";
import { theme } from "styles/theme";

const isActive = (item: CategoryGameType, pathName: string) => {
  if (item.name === "Home" && pathName === "/") return true;

  return item.path !== "/" && pathName.includes(item.path);
};

export const HeaderStyle = {
  index: (backgroundColor: string) => css`
    width: 100%;
    min-width: 1200px;
    height: 75px;
    position: fixed;
    background-color: ${backgroundColor};
    transition: all 0.3s ease;
    z-index: 100;
  `,

  container: () => css`
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
  `,

  heading: css`
    width: 153px;
    height: 44px;
    cursor: pointer;
  `,

  wrapper: () => css`
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  listCategory: () => css`
    display: flex;
    align-items: center;
  `,

  categoryItem: (
    item: CategoryGameType,
    pathname: string,
    colorText: string
  ) => css`
    padding: 0 6px 2px;
    margin: 2px;
    font-weight: 500;
    color: ${isActive(item, pathname) ? theme.colors.darkGreen : colorText};
    font-size: 14px;
    transition: all 0.15s ease;
    border-bottom: ${isActive(item, pathname)
      ? `2px solid ${theme.colors.darkGreen}`
      : "2px solid transparent"};
    cursor: pointer;

    &:hover {
      border-bottom: 3px solid ${theme.colors.darkGreen};
      color: ${theme.colors.darkGreen};
    }
  `,

  menuItem: (colorText: string) => css`
    display: flex;
    flex-direction: column;
    padding: 0 6px;
    cursor: pointer;
    font-size: 14px;
    color: ${colorText};

    span {
      text-align: center;
    }
  `,

  menuItemIcon: () => css`
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      path {
        fill: ${theme.colors.darkGreen};
      }
    }
  `,

  rows: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  `,

  menuList: css`
    display: flex;
    align-items: center;
  `,

  language: css`
    padding: 4px 6px;
    background: linear-gradient(180deg, #fafafa 0%, #f4e7e8 100%);
    margin-left: 12px;
    border-radius: 32px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .icon {
      width: 20px;
      height: 20px;
      border-radius: 20px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .dropdownIcon {
      width: 14px;
      height: 14px;
      margin-left: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: ${theme.colors.gray[200]};
      }
    }
  `,
};
