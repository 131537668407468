import { css } from "@emotion/react";
import ListBackground from "assets/temporary/images/bg_announcement.jpg";
import { theme } from "styles/theme";
export const DetailAnnouncementStyle = {
  modalContainer: () => css`
    .ant-modal-content {
      border-radius: 12px !important;
      overflow: hidden;
      background-color: ${theme.colors.white} !important;
      padding: 0px !important;
    }
  `,
  container: () => css``,
  header: () => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    background-image: url(${ListBackground});
    backface-visibility: initial;
    height: 75px;
    background-size: cover;
    span {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: ${theme.colors.white};
    }
  `,
  buttonClose: () => css`
    width: 24px;
    height: 24px;
    border: 0px;
    background-color: transparent;

    img {
      width: 24px;
      height: 24px;
    }
  `,
  itemAnnouncement: () => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 32px;
  `,
  itemIcon: () => css`
    margin: 0px 6px;
    position: relative;
  `,
  itemContent: () => css`
    margin-left: 8px;
    p:nth-of-type(1) {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: ${theme.colors.black};
    }
    p:nth-of-type(2) {
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      color: ${theme.colors.gray[300]};
    }
    p:nth-of-type(3) {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${theme.colors.black};
      margin-top: 4px;
      padding-right: 8px;
    }
  `,
};
