/** @jsxImportSource @emotion/react */
import { Button } from "antd";
import Logo from "assets/Common/icons/iplay_logo 2.png";
import { ReactComponent as RightArrowSvg } from "assets/temporary/icons/right_icon.svg";
import AvatarDefault from "assets/temporary/images/avatar_default.png";
import { useModalConfirm } from "components/Common/ModalConfirm";
import { PATH } from "contants/path";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useAppLogout } from "hooks/useAppLogout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { selectIsAuthenticated } from "store/auth";
import { selectOpenSidebar, setOpenModal, setOpenSidebar } from "store/modal";
import { selectProfile } from "store/user";
import { theme } from "styles/theme";

import { SideBarStyle } from "./index.style";
const SideBarLeft = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["sidebar", "login", "header"]);

  const onLogout = useAppLogout();
  const { contextHolder, modalConfig } = useModalConfirm();
  const openSideBar = useAppSelector(selectOpenSidebar);
  const isAuth = useAppSelector(selectIsAuthenticated);
  const profile = useAppSelector(selectProfile);
  const handleOpenSideBar = (val: boolean) => {
    dispatch(setOpenSidebar(val));
  };
  const showConfirmLogout = () => {
    modalConfig({
      title: t("login:Logout"),
      onSuccess: () => {
        onLogout();
      },
    });
  };
  const renderAuthMenu = () => {
    return (
      <div css={SideBarStyle.containerAuth}>
        <Link
          to={PATH.User.TransactionHistory}
          onClick={() => handleOpenSideBar(false)}
          css={SideBarStyle.userInfo}
        >
          <img src={AvatarDefault} alt="" />
          <span>{profile?.username}</span>
          <RightArrowSvg fill={theme.colors.gray[200]} />
        </Link>
        {/* <div css={SideBarStyle.ListMenu}>
          <div className="listMenu">
            <Link
              to={PATH.User.Announcement}
              onClick={() => handleOpenSideBar(false)}
              className="item-menu"
            >
              <BellSvg fill={theme.colors.darkGreen} />
              <span>{t("Announcement")}</span>
            </Link>
            <Link
              to={PATH.TermsAndConditions}
              onClick={() => handleOpenSideBar(false)}
              className="item-menu"
            >
              <TandCSvg fill={theme.colors.darkGreen} />
              <span>{t("Terms")}</span>
            </Link>
            <div
              onClick={() => {
                handleOpenSideBar(false);
                dispatch(setOpenModal("language"));
              }}
              className="item-menu"
            >
              <LanguageSvg fill={theme.colors.darkGreen} />
              <span>{t("Language")}</span>
            </div>
            <div
              onClick={() => {
                handleOpenSideBar(false);
                navigate(PATH.contactUs);
              }}
              className="item-menu"
            >
              <PhoneIcon fill={theme.colors.darkGreen} height={20} width={20} />
              <span>{t("Contact")}</span>
            </div>
          </div>
        </div> */}
        <Button
          onClick={() => {
            handleOpenSideBar(false);
            dispatch(setOpenModal("affiliateRegister"));
          }}
          className="affiliateReg"
        >
          <span>{t("header:AFFRegister")}</span>
        </Button>
        <div css={SideBarStyle.logout}>
          <Button onClick={showConfirmLogout} type="text">
            {t("Logout")}
          </Button>
        </div>
        <a css={SideBarStyle.toAffPage} href="affiliate" target="_blank">
          {t("header:Affiliate")}
        </a>
      </div>
    );
  };
  const renderNotAuth = () => {
    return (
      <div css={SideBarStyle.containerNotAuth}>
        <div className="logoApp">
          <img src={Logo} alt="" />
        </div>
        {/* <div className="listMenu">
          <Link
            to={PATH.TermsAndConditions}
            onClick={() => handleOpenSideBar(false)}
            className="item"
          >
            <span>
              <TandCSvg fill={theme.colors.darkGreen} />
            </span>
            <span>{t("Terms")}</span>
          </Link>
          <div
            onClick={() => {
              handleOpenSideBar(false);
              dispatch(setOpenModal("language"));
            }}
            className="item"
          >
            <span>
              <LanguageSvg fill={theme.colors.darkGreen} />
            </span>
            <span>{t("Language")}</span>
          </div>

          <Link
            to={PATH.contactUs}
            onClick={() => handleOpenSideBar(false)}
            className="item"
          >
            <span>
              <PhoneIcon fill={theme.colors.darkGreen} height={20} width={20} />
            </span>
            <span>{t("Contact")}</span>
          </Link>
        </div> */}
        <div className="menuLogin">
          <Button
            onClick={() => {
              handleOpenSideBar(false);
              dispatch(setOpenModal("signin"));
            }}
            className="login"
          >
            <span>{t("login:Login")}</span>
          </Button>
          <Button
            onClick={() => {
              handleOpenSideBar(false);
              dispatch(setOpenModal("signup"));
            }}
            className="register"
          >
            <span>{t("login:Register")}</span>
          </Button>
          <Button
            onClick={() => {
              handleOpenSideBar(false);
              dispatch(setOpenModal("affiliateRegister"));
            }}
            className="affiliateReg"
          >
            <span>{t("header:AFFRegister")}</span>
          </Button>
          <a css={SideBarStyle.toAffPage} href="affiliate" target="_blank">
            {t("header:Affiliate")}
          </a>
        </div>
      </div>
    );
  };
  return (
    <>
      <div css={[SideBarStyle.container, openSideBar && SideBarStyle.open]}>
        {isAuth ? renderAuthMenu() : renderNotAuth()}
      </div>
      {openSideBar && (
        <div
          onClick={() => handleOpenSideBar(false)}
          css={SideBarStyle.overlay}
        />
      )}
      {contextHolder}
    </>
  );
};

export default SideBarLeft;
