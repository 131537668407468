// Live

import * as ImageBoardGame from "assets/Desktop/images/game/board-game";
import * as ImageFishing from "assets/Desktop/images/game/fishing";
import ListSymbolLive from "assets/Desktop/images/game/list_symbol-live.png";
import ListSymbolSport from "assets/Desktop/images/game/list_symbol-sport.png";
import * as ImageLiveCasino from "assets/Desktop/images/game/live-casino";
import * as ImageSlots from "assets/Desktop/images/game/slots";
import * as ImageLiveSport from "assets/Desktop/images/game/sports";
import { GameTypeEnum } from "enums/game";

import { PATH } from "./path";

export interface SymbolsType {
  icon: string;
  iconSelected: string;
  providerCode: string;
}

export interface DataDetailCategoryType {
  providerCode: string;
  titleImage: string;
  description: string;
  image: string;
  providerName: string;
  providerImage?: string;
  listSymbolImg?: string;
}

export interface ListDataType {
  id: string;
  name: string;
  symbols: SymbolsType[];
  data: DataDetailCategoryType[];
  path: string;
}

export const LIST_DATA: ListDataType[] = [
  {
    id: GameTypeEnum.LiveCasino,
    name: "Live",
    path: PATH.liveGamePage,
    symbols: [
      {
        icon: ImageLiveCasino.IconEVO,
        iconSelected: ImageLiveCasino.IconEVOSelected,
        providerCode: "EVO",
      },
      {
        icon: ImageLiveCasino.IconEZ,
        iconSelected: ImageLiveCasino.IconEZSelect,
        providerCode: "EZ",
      },
      {
        icon: ImageLiveCasino.IconSX,
        iconSelected: ImageLiveCasino.IconSXSelected,
        providerCode: "SX",
      },
      {
        icon: ImageLiveCasino.IconSA,
        iconSelected: ImageLiveCasino.IconSASelected,
        providerCode: "SA",
      },
      {
        icon: ImageLiveCasino.IconDG,
        iconSelected: ImageLiveCasino.IconDGSelected,
        providerCode: "DG",
      },
      {
        icon: ImageLiveCasino.IconPP,
        iconSelected: ImageLiveCasino.IconPPSelected,
        providerCode: "PP",
      },
    ],
    data: [
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "EVO",
        titleImage: ImageLiveCasino.titleEVO,
        description: "categoryGame:contentEvolution",
        image: ImageLiveCasino.imageEVO,
        providerName: "categoryGame:Evolution",
        providerImage: ImageLiveCasino.evolutionGaming,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "EZ",
        titleImage: ImageLiveCasino.titleEZ,
        description: "categoryGame:contentEzugi",
        image: ImageLiveCasino.imageEZ,
        providerName: "categoryGame:Big",
        providerImage: ImageLiveCasino.Ezugi,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "SX",
        titleImage: ImageLiveCasino.titleSX,
        description: "categoryGame:contentSexy",
        image: ImageLiveCasino.imageSX,
        providerName: "categoryGame:Sexy",
        providerImage: ImageLiveCasino.SexyBaccarat,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "SA",
        titleImage: ImageLiveCasino.titleSA,
        description: "categoryGame:contentSA",
        image: ImageLiveCasino.imageSA,
        providerName: "categoryGame:SA",
        providerImage: ImageLiveCasino.SaGaming,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "DG",
        titleImage: ImageLiveCasino.titleDG,
        description: "categoryGame:contentDream",
        image: ImageLiveCasino.imageDG,
        providerName: "categoryGame:Dream",
        providerImage: ImageLiveCasino.DreamGaming,
      },
      {
        listSymbolImg: ListSymbolLive,
        providerCode: "PP",
        titleImage: ImageLiveCasino.titlePP,
        description: "categoryGame:contentPlaytech",
        image: ImageLiveCasino.imagePP,
        providerName: "categoryGame:Playtech",
        providerImage: ImageLiveCasino.PragmaticPlay,
      },
    ],
  },
  {
    id: GameTypeEnum.Sport,
    path: PATH.sportGamePage,
    name: "Sports",
    symbols: [
      // {
      //   icon: ImageLiveSport.IconBF,
      //   iconSelected: ImageLiveSport.IconSelectedBF,
      //   providerCode: "BF",
      // },
      {
        icon: ImageLiveSport.iconDE,
        iconSelected: ImageLiveSport.iconSelectedDE,
        providerCode: "DE",
      },
      {
        icon: ImageLiveSport.IconBTI,
        iconSelected: ImageLiveSport.IconSelectedBTI,
        providerCode: "BTI",
      },
      {
        icon: ImageLiveSport.iconIBC,
        iconSelected: ImageLiveSport.IconSelectedIBC,
        providerCode: "IBC",
      },
    ],
    data: [
      // {
      //   listSymbolImg: ListSymbolSport,
      //   providerCode: "BF",
      //   titleImage: ImageLiveSport.titleBF,
      //   description: "categoryGame:contentBetFair",
      //   image: ImageLiveSport.imageSaba,
      //   providerImage: ImageLiveSport.BGSport,
      //   providerName: "categoryGame:BetFair",
      // },
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "DE",
        titleImage: ImageLiveSport.titleDE,
        description: "categoryGame:contentM8",
        image: ImageLiveSport.imageDE,
        providerImage: ImageLiveSport.DESport,
        providerName: "categoryGame:M8",
      },
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "BTI",
        titleImage: ImageLiveSport.titleBTI,
        description: "categoryGame:contentBTI",
        image: ImageLiveSport.imageBTI,
        providerImage: ImageLiveSport.BtiSport,
        providerName: "categoryGame:BTI",
      },
      {
        listSymbolImg: ListSymbolSport,
        providerCode: "IBC",
        titleImage: ImageLiveSport.titleIBC,
        description: "categoryGame:contentM8",
        image: ImageLiveSport.imageMS,
        providerImage: ImageLiveSport.IBCSport,
        providerName: "categoryGame:M8",
      },
    ],
  },
  {
    id: GameTypeEnum.Slot,
    name: "Slots",
    path: PATH.slotGamePage,
    symbols: [],
    data: [
      {
        providerCode: "PP",
        titleImage: ImageLiveSport.titleSaba,
        description: "",
        image: ImageSlots.SlotPragmaticPlay,
        providerImage: ImageSlots.SlotPragmaticPlay,
        providerName: "Pragmatic Play",
      },
      {
        providerCode: "JILI",
        titleImage: ImageLiveSport.titleBTI,
        description: "",
        image: ImageSlots.SlotJili,
        providerImage: ImageSlots.SlotJili,
        providerName: "JILI",
      },
      {
        providerCode: "KA",
        titleImage: ImageLiveSport.titleMS,
        description: "",
        image: ImageSlots.SlotKA,
        providerImage: ImageSlots.SlotKA,
        providerName: "KA Gaming",
      },
      {
        providerCode: "HB",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotHabanero,
        description: "",
        image: ImageSlots.SlotHabanero,
        providerName: "Habanero",
      },
      {
        providerCode: "SG",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotSpadeGaming,
        description: "",
        image: ImageSlots.SlotSpadeGaming,
        providerName: "Spade Gaming",
      },
      {
        providerCode: "PGS2",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotPGS,
        description: "",
        image: ImageSlots.SlotPGS,
        providerName: "Pocket Games Soft",
      },
      {
        providerCode: "ACEW",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotAceWin,
        description: "",
        image: ImageSlots.SlotAceWin,
        providerName: "Ace Win",
      },
      {
        providerCode: "FC",
        titleImage: ImageLiveSport.titleSV,
        providerImage: ImageSlots.SlotFaChai,
        description: "",
        image: ImageSlots.SlotFaChai,
        providerName: "FaChai Gaming",
      },
    ],
  },
  {
    id: GameTypeEnum.Fishing,
    name: "Fishing",
    path: PATH.fishingGamePage,
    symbols: [],
    data: [
      {
        providerCode: "SG",
        titleImage: ImageLiveSport.titleSaba,
        description: "",
        image: ImageFishing.SpadeGamingFishing,
        providerImage: ImageFishing.SpadeGamingFishing,
        providerName: "Spade Gaming",
      },
      {
        providerCode: "JILI",
        titleImage: ImageLiveSport.titleBTI,
        description: "",
        image: ImageFishing.JILIFishing,
        providerImage: ImageFishing.JILIFishing,
        providerName: "JILI",
      },
      {
        providerCode: "ACEW",
        titleImage: ImageLiveSport.titleMS,
        description: "",
        image: ImageFishing.ACEMFishing,
        providerImage: ImageFishing.ACEMFishing,
        providerName: "Ace Win",
      },
    ],
  },
  {
    id: GameTypeEnum.BoardGame,
    name: "Board Games",
    path: PATH.boardGamePage,
    symbols: [],
    data: [
      {
        providerCode: "ACEW",
        titleImage: ImageBoardGame.ImageBoardGameACEM,
        description: "",
        image: ImageBoardGame.ImageBoardGameACEM,
        providerImage: ImageBoardGame.ImageBoardGameACEM,
        providerName: "Ace Win",
      },
      {
        providerCode: "JILI",
        titleImage: ImageBoardGame.ImageBoardGameJILI,
        description: "",
        image: ImageBoardGame.ImageBoardGameJILI,
        providerImage: ImageBoardGame.ImageBoardGameJILI,
        providerName: "JILI",
      },
      {
        providerCode: "KA",
        titleImage: ImageBoardGame.ImageBoardGameKA,
        description: "",
        image: ImageBoardGame.ImageBoardGameKA,
        providerImage: ImageBoardGame.ImageBoardGameKA,
        providerName: "KA",
      },
      {
        providerCode: "KM",
        titleImage: ImageBoardGame.ImageBoardGameKM,
        description: "",
        image: ImageBoardGame.ImageBoardGameKM,
        providerImage: ImageBoardGame.ImageBoardGameKM,
        providerName: "KM",
      },
      {
        providerCode: "SG",
        titleImage: ImageBoardGame.ImageBoardGameSG,
        description: "",
        image: ImageBoardGame.ImageBoardGameSG,
        providerImage: ImageBoardGame.ImageBoardGameSG,
        providerName: "SG",
      },
    ],
  },
];
