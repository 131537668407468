/** @jsxImportSource @emotion/react */
import "./index.css";

import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";

import { ModalConfirmStyles } from "./index.style";

type Props = {
  title: string;
  content?: string;
  onClose?: () => void;
  onSuccess?: () => void;
  isRenderOnly?: boolean;
  textSuccess?: string;
  textClose?: string;
};
export function useModalConfirm() {
  const { t } = useTranslation("button");

  const [modal, contextHolder] = Modal.useModal();
  const modalConfig = (props: Props) => {
    const modalCustom = modal.confirm({ open: false });
    modalCustom.update({
      open: true,
      footer: null,
      closable: false,
      width: "280px",
      style: { padding: 0 },
      centered: true,
      icon: null,
      className: "modal-confirm-hook",
      content: (
        <div css={ModalConfirmStyles.formContainer}>
          <div css={ModalConfirmStyles.header}>
            <span>{props.title}</span>
            {props.content && <span>{props.content}</span>}
          </div>
          <div css={ModalConfirmStyles.contentForm}>
            {!props.isRenderOnly && (
              <Button
                value="cancel"
                css={[
                  ModalConfirmStyles.buttonRadioCancel,
                  ModalConfirmStyles.textButton,
                ]}
                onClick={() => {
                  props.onClose && props.onClose();
                  modalCustom.destroy();
                }}
              >
                {props.textClose ? props.textClose : t("Cancel")}
              </Button>
            )}
            <Button
              value="oke"
              css={[
                ModalConfirmStyles.buttonRadioOk,
                ModalConfirmStyles.textButton,
              ]}
              onClick={() => {
                props.onSuccess && props.onSuccess();
                modalCustom.destroy();
              }}
            >
              {props.textSuccess ? props.textSuccess : t("Ok")}
            </Button>
          </div>
        </div>
      ),
    });
  };
  return { contextHolder, modalConfig };
}
